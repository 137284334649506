.footer {
  display: flex;
  margin: 0 5% 3%;
  flex-direction: column;
  align-items: center;
}

.fun-fact {
  display: flex;
	margin: 5%;
	font-size: 1rem;
	color: rgb(140, 140, 140);
	font-style: italic;
}

.footer-icons {
  display: flex;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.footer-icons span {
  margin: 3%;
}

.footer-low {
  width: 100%;
  display: flex;
  border-top: 1px solid grey;
}

.footer-icons span:hover svg {
  fill: orange;
}

.footer-icons p {
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
}

.footer-desc {
  font-size: 0.8rem;
}
