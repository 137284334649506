.description {
  display: flex;
  /* flex-direction: column; */
  margin: 5% 10%;
  text-align: justify;
  justify-content: space-between;
}

.description-title {
  display: flex;
  justify-content: center;
  font-size: 3em;
  font-family: 'League Spartan';
  font-weight: 700;
}

.description p {
  max-width: 50%;
  margin: 0;
  text-align: end;
}


@media (max-width: 991px) {
  .description {
    flex-direction: column;
  }
  .description p {
    max-width: 100%;
    text-align: justify;
  }
  .description-title {
    color: orange;
    margin-bottom: 1%;
  }
  .highlight-container:before {
    display: none;
  }
}

.highlight-container, .highlight {
  position: relative;
}

.highlight-container {
  display: inline-block;
  margin: 0 10px;
}

.highlight-container:before {
  background-color:orange;

  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: 2rem;
  left: -2px;
  top: 2rem;
  transform: rotate(-2deg);
}
