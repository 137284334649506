.vp-project {
  display: flex;
  flex-direction: column;
  padding: 2%;
	margin: 1%;
	background-color: #2d2d2d54;
  border-radius: 5px;
  max-width: 450px;
  min-width: 250px;
  /* flex: 1 1 25%; */
}

.vp-project-description {
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.vp-project-description p {
  display: flex;
  font-size: 0.9rem;
}

.vp-project-image-container {
  display: flex;
  width: 100%;
  height: 50%;
  justify-content: center;
}

.vp-relative {
  display: flex;
  position: relative;
}


.vp-project .vp-image {
  width: 100%;
  max-width: 450px;
  height: auto;
}

.vp-project-title {
  margin-top: 4%;
  font-family: 'League Spartan';
  font-size: 1.4rem;
  text-align: left;
}

.vp-logo {
  width: 50px !important;
  position: absolute;
  right: 3%;
  top: 3%;
}


/* Mobile, Extra Small */
@media (max-width: 991px) {
  .vp-project {
    flex-direction: column;
  }

  .vp-project .vp-image {
    width: 100%;
  }

  .vp-project-description {
    width: 100%;
  }

  .vp-project-description p {
    width: 100%;
  }
}
