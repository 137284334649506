.App {
  text-align: center;
  background-color: #171719;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Poppins', sans-serif;
}

p {
  font-size: 1rem;
  color: rgb(140, 140, 140);
}

h2 {
  font-family: 'League Spartan';
  font-weight: 900;
}
