.pill {
  background-color: black;
  border-radius: 10px;
  padding: 2em;
  width: 150px;
  display: flex;
  justify-content: center;
}

.pill:nth-child(1) {
  background-image: url(../../../resources/creative/london.webp);
  background-size: cover;
}

.pill:nth-child(2) {
  background-image: url(../../../resources/creative/cowboy.webp);
  background-size: cover;
}

.pill:nth-child(3) {
  background-image: url(../../../resources/creative/hk2.webp);
  background-size: cover;
}

.pill:nth-child(4) {
  background-image: url(../../../resources/creative/ponte.webp);
  background-size: contain;
}

.pill .alternate {
  display: none;
  font-size: 0.78rem;
}

.pill:hover {
  background-color: grey;
  background-blend-mode: multiply;
}

.pill:hover .main {
  display: none;
}

.pill:hover .alternate {
  display: block;
}
