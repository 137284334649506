.prueba {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(min-content, 450px));
	justify-content: center;
	grid-auto-rows: 1fr;
	gap: 10px;
	margin: 0 2%;
}

.projects {
  display: flex;
  margin: 5%;
  flex-direction: column;
}

.projects-title {
  font-family: 'League Spartan';
  margin-top: 10px;
  color: rgb(140, 140, 140);
}

.systems-simulations {
	display: flex;
	flex-direction: column;
}

.wildfire {
  display: flex;
}

.wildfire p {
	font-size: 1.1rem;
}

/**
 * Make images responsive
 */
 .flex .col video {
	width: 100%;
	height: auto;
}

/**
 * Make .flex children same
 * height using display flex.
 * Justify property prepares
 * cols for being centered.
 */
.flex {
	display: flex;
	justify-content: end;
	max-width: 100%;
	margin: auto;
}

/**
 * Make cols flexible to
 * auto push button at the
 * col bottom.
 */
.flex .col {
	display: flex;
	flex-direction: column;
	flex: 0 1 190px;
  justify-content: space-between;
	/*
	In the order, equal to
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 300px;
	*/
}

.flex .col-left {
	display: flex;
	flex-direction: column;
  margin-right: 0.5em;
	/*
	In the order, equal to
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 300px;
	*/
}


.wildfire-container {
	display: flex;
	margin: 3% 5%;
	/* border: 1px solid #ffa50052; */
	border-radius: 5px;
}

.wildfire-description-container {
	display: flex;
	flex-shrink: 1;
	flex-direction: column;
	width: 50%;
	text-align: left;
}
.ss-project {
	display: flex;
	flex-direction: column;
}

.ss-subtitle {
	/* margin: 0; */
}

.wildfire-description {
	width: 80%;
	font-size: 0.9rem;
	font-size: 1rem;
}

.featured {
	margin: 5%;
	margin-top: 0;
}
.featured-container {
	display: flex;
	margin: 3% 5%;
	/* border: 1px solid #ffa50052; */
	border-radius: 5px;
}

.featured-description-container {
	display: flex;
	flex-shrink: 1;
	flex-direction: column;
	width: 50%;
	text-align: left;
}
.ss-project {
	display: flex;
	flex-direction: column;
}

.featured-description {
	width: 80%;
	font-size: 0.9rem;
	font-size: 1rem;
}

.systems-projects {
	width: 100%;
	margin-top: 5%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(min-content, 20rem));
	justify-content: center;
	grid-auto-rows: 1fr;
	gap: 3rem;
	justify-items: center;
}

.ss-project {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.ss-project video {
	width: 100%;
	max-width: 300px;
	height: 225px;
}

.ss {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 5%;
	margin-bottom: 0;
}

.wildfire {
	display: flex;
	flex-direction: column;
}

.wildfire p {
  display: flex;
	align-items: center;
	width: 960px;
	max-width: 100%;
	margin: auto;
	margin-bottom: 2%;
}

.artificial-intelligence {
	display: flex;
	flex-direction: column;
	margin-top: 2%;
}

.artificial-intelligence svg {
	fill: white;
}

.ai-projects {
	width: 100vw;
	margin-top: 2%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(min-content, 20rem));
	justify-content: center;
	grid-auto-rows: 1fr;
	gap: 3rem;
	justify-items: center;
}

.ai-projects p {
	max-width: 300px;
}

.ss-title {
	font-size: 1rem;
	margin: 5% 0;
}

.logos img {
	width: 100%;
	max-width: 80px;
	height: auto;
}

.creative {
	align-items: center;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(15rem, 200px));
	justify-content: center;
	grid-auto-rows: 1fr;
	gap: 3rem;
	justify-items: center;
}

/**
 * Under 900px wrap cols
 */
 @media (max-width: 900px) {
	.ai-projects {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.creative {
		flex-direction: column;
	}
}

@media (max-width: 1200px) {

	.wildfire-container, .featured-container {
		flex-direction: column;
	}
	.wildfire-description-container, .featured-description-container {
		width: 100%;
		text-align: center;
	}

	.wildfire-description-container p {
		text-align: justify;
	}
	.featured-description-container p {
		text-align: justify;
	}

	.wildfire-desc-extra {
		display: none;
	}
	.wildfire-description, .featured-description {
		width: 100%;
	}

	.flex {
		width: 100%;
		justify-content: center;
	}
	.flex .col {
		display: none;
	}

	.flex .col-left {
		margin-right: 0;
	}
	.flex .col-left video {
		width: 100%;
		height: auto;
	}
}

@media (max-width: 1400px) {

	.flex {
		width: 100%;
	}
	.flex .col {
		display: none;
	}

	.flex .col-left video {
		width: 100%;
		height: auto;
	}

	.ai-projects {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.creative {
		flex-direction: column;
	}
}
