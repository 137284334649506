.intro {
  display: flex;
  flex-direction: row;
  margin: 5%;
}

.introRight {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
}

.introPosition {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
}

.introName {
  font-family: 'League Spartan';
  font-weight: 900;
  margin-top: 10px;
  display: flex;
  font-size: 3em;
}

.gallery {
  --s: 180px; /* control the size */
  --g: 10px;  /* control the gap */
  --g: 25px;  /* control the gap */
  display: grid;
  margin: calc(var(--s) + var(--g));
}

.gallery > img {
  grid-area: 1/1;
  width: var(--s);
  object-fit: cover;
  transform: translate(var(--_x,0),var(--_y,0)) scale(var(--_t,1));
  cursor: pointer;
  filter: grayscale(20%);
  transition: .2s linear;
  border-radius: 50%;
  /* clip-path: polygon(25% 0%, 75% 0%, 100% 50%,75% 100%,25% 100%,0 50%);
  aspect-ratio: 1.15; */

}
.gallery > img:hover {
  filter: grayscale(0);
  z-index: 1;
  --_t: 1.4;
}

.gallery > img:nth-child(1) {--_y: calc(-100% - var(--g))}
.gallery > img:nth-child(7) {--_y: calc( 100% + var(--g))}
.gallery > img:nth-child(3),
.gallery > img:nth-child(5) {--_x: calc(-75% - .87*var(--g))}
.gallery > img:nth-child(4),
.gallery > img:nth-child(6) {--_x: calc( 75% + .87*var(--g))}
.gallery > img:nth-child(3),
.gallery > img:nth-child(4) {--_y: calc(-50% - .5*var(--g))}
.gallery > img:nth-child(5),
.gallery > img:nth-child(6) {--_y: calc( 50% + .5*var(--g))}


/* Mobile, Extra Small */
@media (max-width: 991px) {
  .intro {
    flex-direction: column;
  }
  .gallery > img:nth-child(1) {display: none;}
  .gallery > img:nth-child(7) {display: none;}
  .gallery > img:nth-child(3),
  .gallery > img:nth-child(5) {display: none;}
  .gallery > img:nth-child(3),
  .gallery > img:nth-child(4) {display: none;}
  .gallery > img:nth-child(6) {display: none;}
  .gallery  {
    --s: 200px;
    margin: 10%;
    justify-content: center;
  }

}
